<template>
    <div class="order">
        <div class="content">
            <div>
                <span style="flex: 2;">产品名称</span><span style="flex: 1;">金额</span><span style="flex: 1;">数量</span>
            </div>
            <div>全部商品({{ orderData.totalNumber }})</div>

            <div class="order_info">
                <div class="goods_container" v-for="(item, index) in goodsList" :key="index">
                    <div class="order_title">{{ item.companyName }}</div>
                    <div class="goods_item" v-for="items in item.goodsList" :key="items.id">
                        <div class="goods_info" style="flex: 2;">
                            <div>
                                <div>
                                    <img :src="items.goodsPic" />
                                </div>

                                <div>
                                    <span>{{ items.goodsName }}</span>
                                    <span style="font-weight: 400;" v-if="items.specificationText">规格:&nbsp;&nbsp;{{
                                        items.specificationText }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="goods_money" style="flex: 1;">
                            <div>RMB&nbsp;&nbsp;{{ items.productPrice }}</div>
                        </div>

                        <div class="goods_nums" style="flex: 1;">
                            <div>{{ items.lineNumber }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <span @click="dizhiOr = !dizhiOr" style="cursor: pointer;">更改收货地址</span>
                <span>商品总价:&nbsp;&nbsp;<span style="color: #FF000C;">RMB&nbsp;{{ orderData.totalMoney }}元</span></span>
            </div>

            <div class="toMoney">
                <div @click="dizhiOr = !dizhiOr" style="cursor: pointer;">
                    <div class="address_info" v-if="defaultAddress && defaultAddress != -1">
                        联系人：<span>{{ defaultAddress.consignee }} {{ defaultAddress.mobile }}</span>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <span>
                            收货地址: &nbsp;
                            {{ defaultAddress.provinceName + defaultAddress.cityName + defaultAddress.districtName +
                                defaultAddress.address }}
                        </span>
                    </div>
                    <div v-else style="color: #bbb;">您尚未设置默认地址，请设置一个收货地址</div>
                </div>
                <div class="submit" @click="settleAccounts">去付款</div>
            </div>

            <div class="remark">
                <div>备注:</div>
                <textarea v-model="remark" placeholder="请输入内容" style="margin-top: 1%"></textarea>
            </div>
        </div>

        <div class="hidden" v-if="dizhiOr" @click.stop="dizhiOr = !dizhiOr">
            <div class="shose" @click.stop="dizhiOr = dizhiOr">
                <div>
                    <div>选择收货地址:</div>
                    <div class="close" @click.stop="dizhiOr = !dizhiOr"><img src="../assets/icon/nos.svg" /></div>
                </div>
                <ul>
                    <li v-for="(item, index) in dizhiList" :key="index" @click="toShippingAddress(item)">
                        <div><span style="font-size: max(15px,1.3vw);">{{ item.consignee }}</span> <span>{{ item.mobile
                        }}</span></div>
                        <div style="color: rgb(109, 109, 109); font-size: max(13px,0.9vw);"><span v-if="item.isDefault == 1"
                                style="color: rgb(255, 0, 0);">[默认地址]</span> {{ item.provinceName + item.cityName +
                                    item.districtName + item.address }}</div>
                        <span :class="defaultAddress.id == item.id ? 'was' : ''">
                            <svg v-if="defaultAddress.id == item.id" t="1682585656008" class="icon" viewBox="0 0 1024 1024"
                                version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2688" width="200" height="200">
                                <path
                                    d="M63.223 512s231.768 189.585 254.79 350.823c0 0 303.907-497.491 641.582-542.003 0 0-102.837-74.944-69.07-193.396 0 0-187.256 18.685-540.279 566.638l-165.452-280.85L63.223 512z"
                                    p-id="2689" fill="#ffffff"></path>
                            </svg>
                        </span>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            remark: "",// 备注
            goodsList: [],// 需要结算的商品
            defaultAddress: {},// 收货地址
            orderData: {},// 提交购物车数据返回的订单信息
            buyGoodsList: null,// 路由传递的需要结算的购物车商品
            summaryOrderNo: null,// 订单号
            // skipData: {},// 申请支付所需参数
            previousPage: null,// 上一页
            dizhiList: [],//地址列表
            dizhiOr: false,//控制显示隐藏
        };
    },

    created() {
        this.buyGoodsList = JSON.parse(this.$route.query.buyGoodsList)
        this.settleAccount()
        this.askDiZhi()
    },
    computed: {
        ...mapState(["infoObj"]),
    },
    methods: {
        // 提交购物车
        async settleAccount() {
            const loading = this.$loading({
                lock: true,
                text: "加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            const res = await this.$api.getShopIds({//提交购物车数据
                ids: JSON.stringify(this.buyGoodsList),
            })

            if (res && res.code == 200) {//拿到回调信息渲染页面
                this.orderData = res.data;
                this.defaultAddress = this.orderData.address;
                this.goodsList = res.data.productList;
                this.goodsList.forEach(item => {
                    item.goodsList.forEach(value => {
                        const productSpec = value.productSpec ? JSON.parse(value.productSpec).SpecificationsWhole : ''
                        let specificationText = ''
                        for (const key in productSpec) {
                            specificationText += productSpec[key].value + '/'
                        }
                        value.specificationText = specificationText.slice(0, -1)
                    })
                })
            }
            loading.close()
        },

        // 收货地址
        toShippingAddress(item) {
            this.defaultAddress = item
            this.dizhiOr = !this.dizhiOr
        },

        // 用户点击提交订单，发请求给后端生成订单
        async settleAccounts() {
            if (this.defaultAddress && this.defaultAddress == -1) {
                this.$alert(`请选择一个收货地址`, "", {
                    showClose: false,
                    confirmButtonText: "确定",
                });
                return
            }

            let params = {
                myCompanyid: this.infoObj.companyid,
                addressId: this.defaultAddress.id,
                remarks: this.remark,
                goodIds: this.orderData.goodIds.join(),
                goodsOnSumList: JSON.stringify(this.orderData.goodsOnSumList),
                couponsCode: null,
                memberId: this.infoObj.id,
                type: 1,
            }

            let res = await this.$api.makeOrder(params)
            if (res && res.code == 200) {
                this.removeCartGoods();// 删除购物车

                let skipData = {// 支付参数
                    summaryOrderNo: res.data.summaryOrderNo,
                    isMe: 1,
                };

                this.$router.replace({
                    path: "GiveMoney",
                    query: { info: JSON.stringify(skipData) },
                });
                this.summaryOrderNo = res.data.summaryOrderNo;

            } else {
                this.$message.error("创建订单失败,请返回重试")
            }
        },
        // 删除购物车
        async removeCartGoods() {
            const ids = Object.keys(this.buyGoodsList).join();
            const res = await this.$api.delShops({ ids });
        },

        //请求地址列表
        async askDiZhi() {
            let res = await this.$api.getDiZhi()
            if (res.code == 200) {
                this.dizhiList = res.data
            }
        }
    },
};
</script>

<style lang="scss">
.order {
    width: 70%;
    margin: auto;
    font-size: max(14px, 1vw);
    font-weight: bold;
    margin-top: 3%;

    .content {
        width: 100%;
        border: 1px solid #439c4c;
        color: #439c4c;

        >div:nth-child(1) {
            padding: 0% 2%;
            background-color: #439c4c;
            display: flex;

            >span {
                padding: 1% 0%;
                color: white;
                text-align: center;
            }
        }

        >div:nth-child(2) {
            background-color: #EFEFEF;
            padding: 1% 2%;
        }

        .order_info {
            padding: 1% 1.4% 0% 2%;
            height: max(200px, 18vw);
            overflow: auto;
            @include scrollbar();

            .goods_container {
                padding-bottom: 2%;

                .order_title {
                    padding-bottom: 1%;
                    border-bottom: 2px solid #eee;
                }

                .goods_item {
                    display: flex;
                    height: 8vw;
                    color: #999999;

                    >div {
                        text-align: center;
                        border: 1px solid #439c4c;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .goods_info {
                        >div {
                            display: flex;
                            height: 100%;
                            gap: 3%;
                            text-align: start;
                            width: 100%;
                            box-sizing: border-box;
                            padding: 2%;

                            >div:nth-child(1) {
                                flex: 1;

                                >img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            >div:nth-child(2) {
                                flex: 3;

                                >span {
                                    display: block;
                                    margin-bottom: 2%;
                                }
                            }
                        }
                    }
                }
            }
        }

        >div:nth-child(4) {
            background-color: #EFEFEF;
            display: flex;
            justify-content: space-between;
            padding: 1% 2%;
        }

        .toMoney {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1% 2%;

            >div:nth-child(1) {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                .address_info {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            >div:nth-child(2) {
                background-color: #FF000C;
                color: white;
                padding: 0.6% 2.5%;
                font-weight: 600;
                cursor: pointer;
                white-space: nowrap;
            }
        }

        .remark {
            height: 10vw;
            margin: 0% 2%;
            box-sizing: border-box;

            >textarea {
                resize: none;
                width: 100%;
                height: 7vw;
                padding: 0.5%;
                box-sizing: border-box;
            }
        }
    }

    .hidden {
        width: 100vw;
        height: 100vh;
        background-color: rgba(black, 0.7);
        position: fixed;
        z-index: 500;
        top: 0%;
        left: 0%;

        .shose {
            position: fixed;
            z-index: 600;
            top: 10%;
            left: 50%;
            transform: translateX(-50%);
            width: max(280px, 40vw);
            height: max(400px, 36vw);
            background-color: white;
            padding: 2% 3%;

            >div:nth-child(1) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 5px solid #F1F1F1;
                padding-bottom: 3%;

                .close {
                    cursor: pointer;
                    width: max(20px, 1.5vw);
                    height: max(20px, 1.5vw);

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            >ul {
                width: 100%;
                height: 100%;
                box-sizing: border-box;

                >li {
                    margin: 2% 0%;
                    cursor: pointer;
                    position: relative;
                    border-bottom: 2px solid #F1F1F1;
                    padding-bottom: 3%;

                    >div {
                        width: 92%;
                    }

                    >div:nth-child(1) {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                    }

                    >span {
                        display: block;
                        position: absolute;
                        border: 1px solid black;
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        top: 50%;
                        right: 0%;
                        transform: translate(0%, -50%);

                        .icon {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .was {
                        background-color: #F0064D;
                        border: none;
                    }
                }
            }


        }
    }
}

@media screen and (max-width:500px) {
    .order {
        width: 95%;

        .content {
            .remark {
                height: 20vh;

                >textarea {
                    width: 100%;
                    height: 15vh;
                }
            }
        }
    }
}
</style>
  